import React from 'react'

export default function Tour() {
    return (
        <section class="tour">
            <a id="tour-navigation"></a>
            <h2>Tour</h2>
            <p>Tour dates coming soon...</p>
        </section>
    )
}
