import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/font-awesome'

export default function Contact() {
    return (
        <div className="contact">
            <a id="contact-navigation"></a>
            <h2 className="h2-alternate-styles">Contact</h2>
            <div className="contact__icon-container">
                <a href="https://www.facebook.com/poorpremenstrualdarlingband/" ><FontAwesomeIcon size="5x" className="contact__icon" icon={['fab', 'facebook-square']} /></a>
                <a href="https://www.instagram.com/poor_premenstrual_darling_band/?hl=en" target="_blank"><FontAwesomeIcon size="5x" className="contact__icon" icon={['fab', 'instagram-square']} /></a>
                <a href="https://soundcloud.com/welikerecords" target="_blank"><FontAwesomeIcon size="5x" className="contact__icon" icon={['fab', 'soundcloud']} /></a>
            </div>
        </div>
    )
}
