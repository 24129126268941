// import { Link } from 'gatsby'
import React from 'react'
import '../styles/global.css'
import { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import '../utils/font-awesome'

export default function Navbar() {
    // uncheck toggle once navigation section selected
    const [checked, setChecked] = useState(false);
    const handleToggleOff = () => {
        console.log("handleToggleOff");
        setChecked(false);
    }
    const toggleValue = () => {
        setChecked(!checked);
    }
    return (
        <nav className="navbar">
            <label for="toggle">&#9776;</label>
            <input checked={checked} onChange={toggleValue} type="checkbox" id="toggle"></input>
            <ul>
                <li><a class="navbar__link" href="#home-navigation" onClick={handleToggleOff}>HOME</a></li>
                <li><a class="navbar__link" href="#about-navigation" onClick={handleToggleOff}>ABOUT</a></li>
                <li><a class="navbar__link" href="#music-navigation" onClick={handleToggleOff}>MUSIC</a></li>
                <li><a class="navbar__link" href="#tour-navigation" onClick={handleToggleOff}>TOUR</a></li>
                <li><a class="navbar__link" href="#contact-navigation" onClick={handleToggleOff}>CONTACT</a></li>
            </ul>
        </nav>
    )
}


