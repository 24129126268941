import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/font-awesome'

export default function Footer() {
    return (
        <div className="banner-footer">
            <div id="banner-footer__soundcloud">
                <iframe width="50%" height="100" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/860652802&color=%23f62681&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
            </div>
            <div className="banner-footer__icon-container">
                <a href="https://www.facebook.com/poorpremenstrualdarlingband/" target="_blank" ><FontAwesomeIcon size="2x" title='link to Facebook' className="banner-footer__icon" icon={['fab', 'facebook-square']} /></a>
                <a href="https://soundcloud.com/welikerecords" target="_blank"><FontAwesomeIcon size="2x" title='link to Soundcloud' className="banner-footer__icon banner-footer__icon-large" icon={['fab', 'soundcloud']} /></a>
                <a href="https://www.instagram.com/poor_premenstrual_darling_band/?hl=en" target="_blank"><FontAwesomeIcon size="2x" title='link to Instagram' className="banner-footer__icon" icon={['fab', 'instagram-square']} /></a>
            </div>
        </div>
    )
}


