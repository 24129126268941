import React from "react"
import '../styles/global.css'
import Navbar from '../components/Navbar'
import Banner from '../components/Banner'
import Footer from '../components/Footer'
import About from '../components/About'
import Music from '../components/Music'
import Tour from '../components/Tour'
import Contact from '../components/Contact'

export default function Home() {
  return (
    <div>
      <section className="landingPage">
        <Navbar />
        <Banner />
        <Footer />
      </section>
      <About />
      <Music />
      <Tour />
      <Contact />
    </div>
  )
}
