import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function () {
    return (
        <section className="music">
            <a id="music-navigation"></a>
            <h2 className="h2-alternate-styles">Music</h2>
            <div className="music__content-container">
                <div class="music__text-container">
                    <p className="music__text">Where Are We Now? the debut album of Poor Premenstrual Darling, out now.</p>
                    <p className="music__text">Hard copies are available at: Discography Records (Preston), Red Records (Sydney) or email thefemalebassplayer@gmail.com</p>
                    <StaticImage src="../images/album_cover.jpg" className="music__album-img" width={550} placeholder="blurred"
                    alt="Where Are We Now album cover" />
                    <p className="music__text">Check out unreleased tracks on <span><a href="https://soundcloud.com/welikerecords" target="blank">SoundCloud</a></span></p>
                </div>
            </div>
        </section>
    )
}
