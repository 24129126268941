import React from 'react'

export default function About() {
    return (
        <section className="about">
            <a id="about-navigation"></a>
            <h2>About</h2>
            <p>Poor Premenstrual Darling is the live output for the song writing of Marni Newman. Formed in 2020 Marni teamed up with fellow Melbourne musicians to write and record the debut album Where Are We Now? With the pandemic finally under control the band began touring the album in early 2020.</p>
        </section>
    )
}
