import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../utils/font-awesome'

export default function Banner() {
    return (
        <div class="banner">
            <div className="banner__link-icon-container">
                <a href="https://www.facebook.com/poorpremenstrualdarlingband/" target="_blank" ><FontAwesomeIcon size="2x" title='link to Facebook' className="banner__link-icon" icon={['fab', 'facebook-square']} /></a>
                <a href="https://soundcloud.com/welikerecords" target="_blank"><FontAwesomeIcon size="2x" title='link to Soundcloud' className="banner__link-icon bannerIconSoundcloud" icon={['fab', 'soundcloud']} /></a>
                <a href="https://www.instagram.com/poor_premenstrual_darling_band/?hl=en" target="_blank"><FontAwesomeIcon size="2x" title='link to Instagram' className="banner__link-icon" icon={['fab', 'instagram-square']} /></a>
            </div>
            <a id="home-navigation"></a>
            <div class="banner__img-container">
                <StaticImage src="../images/marniBanner1.jpg" className="banner__img" width={600} placeholder="blurred"
                    alt="Marni Newman" />
                <StaticImage src="../images/marniBanner2.jpg" className="banner__img" width={600} placeholder="blurred"
                    alt="Marni Newman" />
                <StaticImage src="../images/marniBanner3.jpg" className="banner__img" width={600} placeholder="blurred"
                    alt="Marni Newman" />
            </div>
            <h1 class="banner__desktop-heading">POOR PREMENSTRUAL DARLING</h1>
            <div class="banner__responsive-text-container">
                <h1 class="banner__responsive-heading" id="banner__responsive-top-heading">POOR PREMENSTRUAL</h1>
                <h1 class="banner__responsive-heading" id="banner__responsive-bottom-heading">DARLING</h1>
            </div>
        </div>
    )
}
